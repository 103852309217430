import React, { useEffect, useState } from "react";

import { Sidebar, Footer, Header } from "./components";
import config from "../../system/config";

const Homepages = (props) => {
  const [isMobileMenuOpened, setIsMobileMenuOpened] = useState(false);
  const [currentPage, setCurrentPage] = useState(window.location.pathname);
  useEffect(() => {
    const anchor = window.document.getElementById(
      `anchor-${currentPage.replace("/", "")}`
    );
    if (anchor) anchor.scrollIntoView({ behavior: "smooth" });
    if (props.isInnerPage) {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    }
    window.document.querySelector("html").classList.add("homepages-body");
    return () => {
      window.document.querySelector("html").classList.remove("homepages-body");
    };
  }, []);

  return (
    <div className={`homepages ${props.isAuth ? "homepages--auth" : ""}`}>
      <div className="homepages__bg">
        <span></span>
        <span></span>
      </div>
      <Sidebar
        setCurrentPage={setCurrentPage}
        currentPage={currentPage}
        isMobileMenuOpened={isMobileMenuOpened}
        setIsMobileMenuOpened={setIsMobileMenuOpened}
      />
      <div className="homepages__content">
        <Header setIsMobileMenuOpened={setIsMobileMenuOpened} />
        <main className="page-content">{props.children}</main>
        {props.isAuth && !props.noImage && (
          <div className="auth-img">
            <img
              src={`${config.imagesDirectory}/auth-img.png`}
              alt=""
              loading="lazy"
              width={872}
            />
          </div>
        )}
        {!props.isAuth && <Footer setCurrentPage={setCurrentPage} />}
      </div>
    </div>
  );
};

export default Homepages;
