import React from "react";
import { Link, useHistory } from "react-router-dom";
import config from "../../../../system/config";
import HomepagesNav from "../HomepagesNav/HomepagesNav";
import { _t } from "../../../../modules/Common/components/InjectIntlContext";
import { APP_ROUTES } from "../../../../system/constants/constantsUrl";

const items = (t) => [
  {
    icon: "image_tg.svg",
    path: t("project_social_contact_tg"),
  },
  {
    icon: "image_skype.svg",
    path: t("project_social_contact_skype"),
  },
];
const Sidebar = ({
  setCurrentPage,
  currentPage,
  isMobileMenuOpened,
  setIsMobileMenuOpened,
}) => {
  const socialItems = items(_t);
  const history = useHistory();

  return (
    <div className={`homepage-sidebar ${isMobileMenuOpened ? "opened" : ""}`}>
      <div className="homepage-sidebar__content">
        <div className="homepage-sidebar__top">
          <Link to="/" className="homepage-sidebar__logo">
            <img src={`${config.imagesDirectory}/logo.svg`} alt="" />
          </Link>
          <button
            className="close-button"
            onClick={() => setIsMobileMenuOpened(false)}
          />
        </div>
        <HomepagesNav
          setCurrentPage={setCurrentPage}
          setIsMobileMenuOpened={setIsMobileMenuOpened}
          currentPage={currentPage}
        />
        <div className="homepage-sidebar__mobile-buttons">
          <button
            className="homepage-button homepage-button--small homepage-button--secondary"
            onClick={() => history.push(APP_ROUTES.login.main)}
          >
            <span>{_t("sign_in")}</span>
          </button>
          <button
            className="homepage-button homepage-button--small"
            onClick={() => history.push(APP_ROUTES.registration)}
          >
            <span>{_t("sign_up")}</span>
          </button>
        </div>
        <ul className="social-contacts">
          {socialItems.map((item, idx) => (
            <li key={idx}>
              <a href={item.path} target="_blank" rel="noreferrer">
                <img
                  src={`${config.imagesDirectory}/social/${item.icon}`}
                  alt=""
                />
              </a>
            </li>
          ))}
        </ul>

        <p className="copyright">
          © {new Date().getFullYear()}
          <br />
          All rights reserved by Garage Stream
        </p>
      </div>
    </div>
  );
};

export default Sidebar;
