import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Section from "../Section";
import { _t } from "../../../../modules/Common/components/InjectIntlContext";
import config from "../../../../system/config";
import { useHistory } from "react-router-dom";
import { APP_ROUTES } from "../../../../system/constants/constantsUrl";

const slickSettings = {
  dots: true,
  infinite: true,
  arrows: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 8000,
  fade: true,
};

const items = (_t) => [
  {
    title: _t("main_banner_title_1"),
    text: _t("main_banner_text_1"),
    image: "image_1.jpg",
  },
  {
    title: _t("main_banner_title_1"),
    text: _t("main_banner_text_1"),
    image: "image_1.jpg",
  },
  {
    title: _t("main_banner_title_1"),
    text: _t("main_banner_text_1"),
    image: "image_1.jpg",
  },
];

const MainBanner = () => {
  const slides = items(_t);
  const history = useHistory();

  return (
    <Section id="about" sectionClass="main-banner-section">
      <Slider {...slickSettings}>
        {slides.map((item, idx) => (
          <div key={idx}>
            <div className="main-banner">
              <div className="main-banner__image">
                <img
                  src={`${config.imagesDirectory}/banners/${item.image}`}
                  loading="lazy"
                  alt=""
                />
              </div>
              <div className="main-banner__content">
                <h2 className="main-banner__title">
                  {_t("main_banner_title_1")}
                </h2>
                <h6 className="main-banner__text">
                  {_t("main_banner_text_1")}
                </h6>
                <div className="main-banner__buttons">
                  <button
                    className="homepage-button"
                    onClick={() => history.push(APP_ROUTES.registration)}
                  >
                    <span>{_t("get_started")}</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </Section>
  );
};

export default MainBanner;
